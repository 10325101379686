import { Box, Button, Header, Icon, Loader, Table, Text } from '@profitowi/component-library';
import { AxiosError, AxiosResponse } from 'axios';
import { endOfDay, format, startOfDay } from 'date-fns';
import { Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { SortingRule } from 'react-table';
import * as Yup from 'yup';

import ErrorMessages from 'components/ErrorMessages/ErrorMessages';

import ConfirmationModal from '../../../../../components/ConfirmationModal/ConfirmationModal';
import { requiredFieldMessage } from '../../../../../constants/requiredFieldMessage';
import { usePagination } from '../../../../../hooks/usePagination';
import {
  archiveTemplates,
  deleteTemplates,
  getTemplateCreatorData,
  restoreTemplates,
} from '../../../../../services/templateCreator';
import {
  TemplateCreatorData,
  TemplateCreatorDataContent,
  TemplateFilterRequestParams,
} from '../../../../../types/templateCreator';
import { decodeUriSortParams } from '../../../../../utils/table';
import { TemplateCreatorTabs } from '../TemplateCreator';
import { TemplateCreatorActions, createColumn } from './columns';
import TemplateCreatorListFilters from './components/TemplateCreatorListFilters';

export enum TemplateStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

const validationSchema = Yup.object({
  templateStatus: Yup.mixed<TemplateStatus>()
    .oneOf(Object.values(TemplateStatus))
    .required(requiredFieldMessage),
  name: Yup.string(),
  documentType: Yup.mixed(),
  createdAfter: Yup.string(),
  createdBefore: Yup.string(),
  lastModifiedAfter: Yup.string(),
  lastModifiedBefore: Yup.string(),
});

export type FormValues = Yup.InferType<typeof validationSchema>;

const initialValues: FormValues = {
  name: undefined,
  templateStatus: TemplateStatus.ACTIVE,
  documentType: undefined,
  createdAfter: undefined,
  createdBefore: undefined,
  lastModifiedAfter: undefined,
  lastModifiedBefore: undefined,
};

interface Params {
  setCurrentTab: (tab: TemplateCreatorTabs) => void;
  setTemplateFormAction: (action: TemplateCreatorActions | undefined) => void;
  setEditId: (action: number | undefined) => void;
}

function TemplateCreatorList({ setCurrentTab, setTemplateFormAction, setEditId }: Params) {
  const pagination = usePagination(10);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [editedObjectAction, setEditedObjectAction] = useState<{
    objectId: number;
    action: TemplateCreatorActions;
  }>();
  const [createdAtError, setCreatedAtError] = useState<string>();
  const [lastModifiedAtError, setLastModifiedAtError] = useState<string>();
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showRestoreModal, setShowRestoreModal] = useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const { currentPage, perPage } = pagination;
  const [sortBy, setSortBy] = useState<Array<SortingRule<any>>>([
    { desc: true, id: 'lastModified' },
  ]);
  const handleSortBy = useCallback((sortBy: SortingRule<any>[]) => setSortBy(sortBy), []);
  const [payload, setPayload] = useState<
    Omit<TemplateFilterRequestParams, 'templateStatus'> & { archived: boolean }
  >({
    archived: false,
    ...{ ...initialValues, templateStatus: undefined },
  });

  const { data, refetch, isError, isSuccess, isLoading, error, isFetching } = useQuery<
    TemplateCreatorData,
    AxiosError
  >(
    ['templateCreator', payload, currentPage, perPage, sortBy],
    () => getTemplateCreatorData(payload, currentPage, perPage, decodeUriSortParams(sortBy)),
    {
      keepPreviousData: true,
    }
  );

  const {
    error: archiveTemplatesError,
    isError: archiveTemplatesIsError,
    isLoading: archiveTemplatesIsLoading,
    mutate: archiveTemplatesMutate,
    reset: archiveTemplateReset,
  } = useMutation<AxiosResponse, AxiosError, void>(
    () => archiveTemplates(editedObjectAction ? [editedObjectAction.objectId] : selectedRows),

    {
      onSuccess: () => {
        refetch();
      },
      onSettled: () => {
        setEditedObjectAction(undefined);
        setShowArchiveModal(false);
      },
    }
  );

  const {
    error: restoreTemplatesError,
    isError: restoreTemplatesIsError,
    isLoading: restoreTemplatesIsLoading,
    mutate: restoreTemplatesMutate,
    reset: restoreTemplateReset,
  } = useMutation<AxiosResponse, AxiosError, void>(
    () => restoreTemplates(editedObjectAction ? [editedObjectAction.objectId] : selectedRows),
    {
      onSuccess: () => {
        refetch();
      },
      onSettled: () => {
        setEditedObjectAction(undefined);
        setShowRestoreModal(false);
      },
    }
  );

  const {
    error: deleteTemplatesError,
    isError: deleteTemplatesIsError,
    isLoading: deleteTemplatesIsLoading,
    mutate: deleteTemplatesMutate,
    reset: deleteTemplateReset,
  } = useMutation<AxiosResponse, AxiosError, void>(
    () => deleteTemplates(editedObjectAction ? [editedObjectAction.objectId] : selectedRows),
    {
      onSuccess: () => {
        refetch();
      },
      onSettled: () => {
        setEditedObjectAction(undefined);
        setShowDeleteModal(false);
        deleteTemplateReset();
      },
    }
  );

  const handleSelectionChange = useCallback(
    (rows: TemplateCreatorDataContent[]) => {
      selectedRows.length !== rows.length && setSelectedRows(rows.map(({ id }) => id));
    },
    [selectedRows.length]
  );

  function handleSubmit(values: FormValues) {
    pagination.setPage(0);
    setCreatedAtError(undefined);
    setLastModifiedAtError(undefined);
    let forceReturn: boolean = false;

    if (values.createdBefore && values.createdAfter && values.createdBefore < values.createdAfter) {
      setCreatedAtError('Data początkowa nie może następować po dacie końcowej!');
      forceReturn = true;
    }

    if (
      values.lastModifiedBefore &&
      values.lastModifiedAfter &&
      values.lastModifiedBefore < values.lastModifiedAfter
    ) {
      setLastModifiedAtError('Data początkowa nie może następować po dacie końcowej!');
      forceReturn = true;
    }

    if (forceReturn) return;

    const tempPayload = {
      archived: values.templateStatus === TemplateStatus.ACTIVE ? false : true,
      name: values.name,
      documentType: values.documentType,
      lastModifiedAfter:
        values.lastModifiedAfter &&
        format(startOfDay(new Date(values.lastModifiedAfter)), 'dd-MM-yyyy HH:mm:ss'),
      lastModifiedBefore:
        values.lastModifiedBefore &&
        format(endOfDay(new Date(values.lastModifiedBefore)), 'dd-MM-yyyy HH:mm:ss'),
      createdAfter:
        values.createdAfter &&
        format(startOfDay(new Date(values.createdAfter)), 'dd-MM-yyyy HH:mm:ss'),
      createdBefore:
        values.createdBefore &&
        format(endOfDay(new Date(values.createdBefore)), 'dd-MM-yyyy HH:mm:ss'),
    };

    if (JSON.stringify(tempPayload) !== JSON.stringify(payload)) setPayload(tempPayload);
  }

  function handleReset(setFieldValue: (fieldName: string, fieldValue: string) => void) {
    setCreatedAtError(undefined);
    setLastModifiedAtError(undefined);
    setFieldValue('name', '');
    setFieldValue('createdAfter', undefined as any);
    setFieldValue('createdBefore', undefined as any);
    setFieldValue('lastModifiedAfter', undefined as any);
    setFieldValue('lastModifiedBefore', undefined as any);
    setFieldValue('documentType', undefined as any);
    setPayload(
      (payload) =>
        ({
          archived: payload.archived,
          ...{ ...initialValues, templateStatus: (payload as any).templateStatus },
        } as any)
    );
  }

  function handleTemplateStatusChange(templateStatus: boolean) {
    setPayload((payload) => ({
      ...payload,
      archived: templateStatus,
    }));
  }

  useEffect(() => {
    refetch();
  }, [payload.archived]);

  const archiveSelectedTemplates = useCallback((): void => {
    setShowArchiveModal(true);
  }, []);

  const restoreSelectedTemplates = useCallback((): void => {
    setShowRestoreModal(true);
  }, []);

  const deleteSelectedTemplates = useCallback((): void => {
    setShowDeleteModal(true);
  }, []);

  useEffect(() => {
    if (!editedObjectAction) return;
    if (editedObjectAction.action === TemplateCreatorActions.DELETE) {
      deleteSelectedTemplates();
    }
    if (editedObjectAction.action === TemplateCreatorActions.RESTORE) {
      restoreSelectedTemplates();
    }
    if (editedObjectAction.action === TemplateCreatorActions.ARCHIVE) {
      archiveSelectedTemplates();
    }
    if (
      editedObjectAction.action === TemplateCreatorActions.EDIT ||
      editedObjectAction.action === TemplateCreatorActions.COPY
    ) {
      setTemplateFormAction(editedObjectAction.action);
      setEditId(editedObjectAction.objectId);
      setCurrentTab(TemplateCreatorTabs.FORM_VIEW);
    }
  }, [
    archiveSelectedTemplates,
    deleteSelectedTemplates,
    editedObjectAction,
    restoreSelectedTemplates,
    setCurrentTab,
    setEditId,
    setTemplateFormAction,
  ]);

  const columns = useMemo(() => {
    return createColumn(setEditedObjectAction, payload.archived);
  }, [payload.archived]);

  function createNewTemplate() {
    setTemplateFormAction(TemplateCreatorActions.CREATE);
    setCurrentTab(TemplateCreatorTabs.FORM_VIEW);
  }

  return (
    <>
      <Box className="space-y-6">
        <div className="flex justify-between">
          <Header as="h4" size="lg" weight="semibold">
            Kreator szablonów
          </Header>
        </div>

        <div className="flex justify-between w-full">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}>
            <>
              <TemplateCreatorListFilters
                handleReset={handleReset}
                handleTemplateStatusChange={handleTemplateStatusChange}
                createdAtError={createdAtError}
                lastModifiedAtError={lastModifiedAtError}
              />
            </>
          </Formik>
          <div className="flex flex-col gap-4">
            {payload.archived ? (
              <>
                <Button
                  className="w-56"
                  size="sm"
                  variant="outline-primary"
                  isDisabled={!selectedRows.length}
                  onPress={() => deleteSelectedTemplates()}>
                  <Text weight="semibold" className="flex gap-2 items-center w-full">
                    <Icon custom={false} name="trash-fill" />
                    Usuń zaznaczone
                  </Text>
                </Button>
                <Button
                  className="w-56"
                  size="sm"
                  variant="outline-primary"
                  isDisabled={!selectedRows.length}
                  onPress={() => restoreSelectedTemplates()}>
                  <Text weight="semibold" className="flex gap-2 items-center w-full">
                    <Icon custom={false} name="arrow-counterclockwise" />
                    Przywróć zaznaczone
                  </Text>
                </Button>
              </>
            ) : (
              <>
                <Button
                  className="w-56"
                  size="sm"
                  variant="outline-primary"
                  onPress={() => createNewTemplate()}>
                  <Text weight="semibold" className="flex gap-2 items-center w-full">
                    <Icon custom={false} name="plus-lg" />
                    Dodaj nowy
                  </Text>
                </Button>
                <Button
                  className="w-56"
                  size="sm"
                  variant="outline-primary"
                  isDisabled={!selectedRows.length}
                  onPress={() => archiveSelectedTemplates()}>
                  <Text weight="semibold" className="flex gap-2 items-center w-full">
                    <Icon custom={false} name="archive-fill" />
                    Archiwizuj zaznaczone
                  </Text>
                </Button>
              </>
            )}
          </div>
        </div>

        {isError && <ErrorMessages error={error} />}
        {isLoading && <Loader className="h-12 w-12" />}
        {isSuccess && data && (
          <Box.FullWidth>
            <Table
              columns={columns}
              data={data.content}
              isLoading={isFetching}
              isSelectable
              onSelectionChange={handleSelectionChange}
              pagination={pagination}
              onSortBy={handleSortBy}
              sortBy={sortBy}
              totalPages={data?.totalPages}
            />
          </Box.FullWidth>
        )}
      </Box>

      {showDeleteModal && (
        <ConfirmationModal
          title={
            editedObjectAction || selectedRows.length === 1
              ? 'Usuń szablon'
              : 'Usuń zaznaczone szablony'
          }
          modalBody={
            <div className="flex flex-col gap-">
              <Text className="text-center">
                Czy na pewno chcesz usunąć
                {editedObjectAction || selectedRows.length === 1
                  ? ' wybrany szablon'
                  : ' zaznaczone szablony'}
                ?
              </Text>
              <Text className="text-center">
                Po zatwierdzeniu akcji nie będzie możliwości przywrócenia.
              </Text>
            </div>
          }
          isError={deleteTemplatesIsError}
          error={deleteTemplatesError}
          isLoading={deleteTemplatesIsLoading}
          mutate={deleteTemplatesMutate}
          onCancel={() => {
            setEditedObjectAction(undefined);
            setShowDeleteModal(false);
            deleteTemplateReset();
          }}
        />
      )}
      {showArchiveModal && (
        <ConfirmationModal
          title={
            editedObjectAction || selectedRows.length === 1
              ? 'Archiwizuj szablon'
              : 'Archiwizuj zaznaczone szablony'
          }
          modalBody={
            <div className="flex flex-col gap-">
              <Text className="text-center">
                Czy na pewno chcesz zarchiwizować
                {editedObjectAction || selectedRows.length === 1
                  ? ' wybrany szablon'
                  : ' zaznaczone szablony'}
                ?
              </Text>
            </div>
          }
          isError={archiveTemplatesIsError}
          error={archiveTemplatesError}
          isLoading={archiveTemplatesIsLoading}
          mutate={archiveTemplatesMutate}
          onCancel={() => {
            setEditedObjectAction(undefined);
            setShowArchiveModal(false);
            archiveTemplateReset();
          }}
        />
      )}
      {showRestoreModal && (
        <ConfirmationModal
          title={
            editedObjectAction || selectedRows.length === 1
              ? 'Przywróć szablon'
              : 'Przywróć zaznaczone szablony'
          }
          modalBody={
            <div className="flex flex-col gap-">
              <Text className="text-center">
                Czy na pewno chcesz przywrócić do użytku
                {editedObjectAction || selectedRows.length === 1
                  ? ' wybrany szablon'
                  : ' zaznaczone szablony'}
                ?
              </Text>
            </div>
          }
          isError={restoreTemplatesIsError}
          error={restoreTemplatesError}
          isLoading={restoreTemplatesIsLoading}
          mutate={restoreTemplatesMutate}
          onCancel={() => {
            setEditedObjectAction(undefined);
            setShowRestoreModal(false);
            restoreTemplateReset();
          }}
        />
      )}
    </>
  );
}

export default TemplateCreatorList;
